<template>
   
  <div class="all_main_wrap">
    <HeaderBox>
      <template #title> 商品分类 </template>
      <template #input>
        <a-button class="all_boder_btn" @click="newbuilt(rowstr)"
          >新建分类</a-button
        >
      </template>
    </HeaderBox>
    <a-table
      class="table-template"
      :expandIcon="expandIcon"
      :columns="columns"
      :data-source="data"
      :defaultExpandAllRows="true"
      :pagination="false"
      :rowKey="(item) => item.id"
      :loading="loading"
      childrenColumnName="children"
    >
      <template slot="show" slot-scope="item, row">
        <template v-if="!(row.first == 1 && row.last == 1)">
          <a v-if="row.last == 0" @click="moveData(1, row.id)">前移</a>
          <span v-if="row.fast == 0 && row.last == 0"> | </span>
          <a v-if="row.fast == 0" @click="moveData(2, row.id)">后移</a>
        </template>
        <span v-else>-</span>
      </template>

      <template slot="publishFlag" slot-scope="item">
        {{ item == 1 ? "已上架" : "未上架" }}
      </template>

      <template slot="catalogue" slot-scope="item, row">
        <a @click="seledit(row)">编辑</a>
        <span> | </span>
        <a @click="removeData(row.id)">删除</a>
        <span v-if="row.pid == 0"> | </span>
        <a v-if="row.pid == 0" @click="newbuilt(row)">新建子分类</a>
        <span> | </span>
        <a v-if="row.publishFlag != 1" @click="changitem(row.id,1)">上架</a>
        <a @click="changitem(row.id,0)">下架</a>
      </template>
    </a-table>

    <!-- 新建/编辑 -->
    <a-modal
      v-model="visible"
      :confirm-loading="PreventLoad"
      :title="
        (state == 1 ? '新建' : '编辑') +
        (pid == 0 || (state == 2 && pid != 0) ? '' : '子') +
        '分类'
      "
      @ok="state == 1 ? addData() : setData()"
      okText="确认"
    >
      <!-- 根分类 -->
      <template v-if="pid == 0">
        <a-row class="rows">
          <a-col span="5" class="left">分类名称</a-col>
          <a-col span="19" class="right">
            <a-input placeholder="请输入分类名称" v-model="name" />
          </a-col>
        </a-row>
      </template>
      <!-- 子分类 -->
      <template v-else>
        <a-row class="rows">
          <a-col span="5" class="left">当前分类</a-col>
          <a-col span="19" class="right">
            <div style="line-height: 32px">{{ levelName }}</div>
          </a-col>
        </a-row>
        <a-row class="rows">
          <a-col span="5" class="left">子分类名称</a-col>
          <a-col span="19" class="right">
            <a-input placeholder="请输入子分类名称" v-model="name" />
          </a-col>
        </a-row>
      </template>
    </a-modal>
  </div>
</template>

<script>
import HeaderBox from "@/components/HeaderBox.vue";
const columns = [
  {
    title: "分类名称",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "机构端上架状态",
    align: "left",
    dataIndex: "publishFlag",
    key: "publishFlag",
    scopedSlots: { customRender: "publishFlag" },
  },
  {
    title: "终端展示排序",
    align: "left",
    dataIndex: "show",
    key: "show",
    scopedSlots: { customRender: "show" },
  },
  {
    title: "目录操作",
    // align: "center",
    dataIndex: "catalogue",
    key: "catalogue",
    scopedSlots: { customRender: "catalogue" },
  },
];

export default {
  // 可用组件的哈希表
  components: { HeaderBox }, // 接收传值
  props: {}, // 数据对象
  data() {
    return {
      PreventLoad:false,
      pid: 0, // 分类id
      state: 1, // 1新建，2编辑

      levelName: "",
      id: null,
      visible: false,
      data: [],
      columns,
      name: "",
      loading: false,
      // expandedRowKeys: [],

      // 专用新建数据
      rowstr: {
        id: 0,
      },
    };
  }, // 事件处理器
  methods: {
    // 给第一个和最后一个添加标识
    Findadifference(e) {
      let arr = e;
      // 判断长度，如果只有一条就不走下面的
      for (let index = 0; index < arr.length; index++) {
        if (arr.length - 1 != 0) {
          if (index == 0) {
            arr[index].last = 1;
          } else {
            arr[index].last = 0;
          }
          if (index == arr.length - 1) {
            arr[index].fast = 1;
          } else {
            arr[index].fast = 0;
          }
        }
        // 内层赋值
        if (arr[index].children.length != 0 && arr[index].children != null) {
          for (let y = 0; y < arr[index].children.length; y++) {
            if (arr[index].children.length - 1 != 0) {
              if (y == 0) {
                arr[index].children[y].last = 1;
              } else {
                arr[index].children[y].last = 0;
              }
              if (y == arr[index].children.length - 1) {
                arr[index].children[y].fast = 1;
              } else {
                arr[index].children[y].fast = 0;
              }
            }
          }
        }
      }
      // 如果数组中有children则继续递归
      this.data = arr;
    },
    expandIcon(props) {
      if (props.record.children && props.record.children.length > 0) {
        if (props.expanded) {
          //有数据-展开时候图标
          return (
            <a
              style="color: 'black',margin-right:0px"
              onClick={(e) => {
                props.onExpand(props.record, e);
              }}
            >
              <a-icon slot="suffixIcon" type="caret-down" />{" "}
            </a>
          );
        } else {
          //有数据-未展开时候图标
          return (
            <a
              style="color: 'black' ,margin-right:0px"
              onClick={(e) => {
                props.onExpand(props.record, e);
              }}
            >
              <a-icon slot="suffixIcon" type="caret-right" />
            </a>
          );
        }
      } else {
        return <span style={{ marginRight: 8 }}></span>;
      }
    },
    // 一系列操作
    // 编辑
    seledit(row) {
      console.log(row);
      this.state = 2;
      this.id = row.id;
      this.pid = row.pid;
      this.visible = true;
      this.name = row.name;
      if (row.pid != 0) {
        this.levelName = this.getLevelName(row.pid);
      }
    },
    // 新建
    newbuilt(row) {
      this.state = 1; //新建
      this.pid = row.id; //继承id
      this.levelName = row.name; //继承name
      this.name = null; //清空
      this.visible = true; //打开弹框
    },
    // 查询列表数据
    getList() {
      this.loading = true;
      this.$ajax({
        url: "/hxclass-management/organiz-product-categroy/tree",
        params: {
          type: 1,
          pid: 0,
        },
      })
        .then((res) => {
          if (res.code == 200 && res.success) {
            this.Findadifference(res.data);
          }
          this.loading = false;
          this.name = "";
          this.levelName = "";
        })
        .catch((err) => {
          this.loading = false;
          this.name = "";
          this.levelName = "";
        });
    },

    // 新建分类
    addData() {
      if (this.name == "") {
        if (this.pid == 0) {
          this.$message.error("请输入分类名称");
        } else {
          this.$message.error("请输入子分类名称");
        }
        return false;
      } else if (this.name.length > 4 && this.pid == 0) {
        this.$message.error("分类名称不能超过4个字");
        return false;
      } else if (this.name.length > 8) {
        this.$message.error("分类名称不能超过8个字");
        return false;
      }
      this.PreventLoad = true
      this.$ajax({
        url: "/hxclass-management/organiz-product-categroy/save",
        method: "post",
        params: {
          name: this.name,
          pid: this.pid,
          type: 1,
        },
      }).then((res) => {
        this.PreventLoad = false
        if (res.code == 200 && res.success) {
          this.visible = false;
          this.getList();
        } else {
          this.$message.error(res.message);
        }
      });
    },

    // 修改分类
    setData() {
      if (this.name == "") {
        if (this.pid == 0) {
          this.$message.error("请输入分类名称");
        } else {
          this.$message.error("请输入子分类名称");
        }
        return false;
      } else if (this.name.length > 4 && this.pid == 0) {
        this.$message.error("分类名称不能超过4个字");
        return false;
      } else if (this.name.length > 8) {
        this.$message.error("分类名称不能超过8个字");
        return false;
      }
      this.$ajax({
        url: "/hxclass-management/organiz-product-categroy/edit",
        method: "put",
        params: {
          id: this.id,
          name: this.name,
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.visible = false;
          this.getList();
        } else {
          this.$message.error(res.message);
        }
      });
    },

    // 删除分类
    removeData(e) {
      this.$confirm({
        title: "确定删除该标签吗?",
        okText: "确认",
        onOk: () => {
          this.$ajax({
            url: "/hxclass-management/organiz-product-categroy/deleted?id=" + e,
            method: "put",
          }).then((res) => {
            if (res.code == 200 && res.success) {
              this.getList();
            } else {
              this.$message.error(res.message);
            }
          });
        },
      });
    },

    // 移动
    moveData(e, r) {
      if (this.loading) {
        return false;
      } else {
        this.loading = true;
      }
      this.$ajax({
        url:
          "/hxclass-management/organiz-product-categroy/edit-sort?id=" +
          r +
          "&type=" +
          e,
        method: "put",
        // params: {
        //   id: r,
        //   type: e,
        // },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.getList();
          setTimeout(() => {
            this.loading = false;
          }, 2000);
        } else {
          this.$message.error(res.message);
        }
      });
    },

    // 查询父级分类
    getLevelName(id) {
      let name = "";
      function forId(arr) {
        arr.forEach((res) => {
          if (res.id == id) {
            name = res.name;
          } else if (res.children && res.children.length > 0) {
            forId(res.children);
          }
        });
      }
      forId(this.data);
      return name;
    },
    // 上下架
    // 修改分类
    changitem(e, r) {
      this.$ajax({
        url: "/hxclass-management/organiz-product-categroy/edit",
        method: "put",
        params: {
          id: e,
          publishFlag: r,
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.getList();
        } else {
          this.$message.error(res.message);
        }
      });
    },
  }, // 生命周期-实例创建完成后调用
  created() {
    this.getList();
  }, // 生命周期-实例挂载后调用
  mounted() {}, // 生命周期-实例销毁离开后调用
  destroyed() {}, // 计算属性监听
  computed: {}, // 自定义的侦听器
  watch: {},
};
</script>

<style lang="less" scoped>
.all_main_wrap {
  min-height: 100%;
  .btn {
    margin: 0 24px 14px 0;
  }
  a {
    color: @theme;
  }
}
.rows {
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
  .left {
    text-align: right;
    height: 32px;
    line-height: 32px;
    &::before {
      content: "*";
      color: red;
      margin-right: 2px;
    }
    &::after {
      content: "：";
    }
  }
  .flex {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 32px;
    input {
      flex: 1;
    }
    a {
      margin-left: 8px;
    }
  }
}
</style>
